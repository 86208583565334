<template>
  <div>
    <selection-form
      :data="passedData"
      :isShowRate="isShowRate"
      :unitsList="unitsList"
      @addcondition="addSelectedCondition"
      @delcondition="delSelectedCondition"
      @setlabourquantity="setLabourQuantity"
      @setunitsone="setUnitsOne"
      @sethourlyprice="setHourlyPrice"
      @setunitstwo="setUnitsTwo"
    >
      <div>
        <p class="input-label required">{{ $t("Service") }}</p>
        <v-select
          solo
          :items="conditionsList"
          :value="currentSelectedCondition"
          :label="$t('Select condition and click +')"
          item-text="title"
          item-value="id"
          return-object
          @change="setCurrentSelectedCondition"
        />
      </div>
    </selection-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SelectionFormPrototype from "@/components/ContractModals/Components/SelectionFormProto";

export default {
  components: {
    "selection-form": SelectionFormPrototype
  },
  props: {
    isShowRate: {
      type: Boolean,
      default: true
    },
    unitsList: {
      type: Array,
      default: function() {
        return ["pcs", "hours"];
      }
    }
  },
  computed: {
    ...mapState("QuotationContractModal", ["requestedServices", "serviceCurrency"]),
    conditionsList() {
      // return this.$store.state.additionalConditions || [];
      return this.$store.state.servicesList || [];
    },
    currentSelectedCondition() {
      return this.requestedServices.currentSelectedCondition;
    },
    selectedConditions() {
      return this.requestedServices.selectedConditions;
    },
    servicePrice() {
      return this.requestedServices.servicePrice
        ? `${this.requestedServices.servicePrice} ${this.serviceCurrency}`
        : this.serviceCurrency;
    },
    labourQuantity() {
      return this.requestedServices.labourQuantity;
    },
    unitsOne() {
      return this.requestedServices.unitsOne;
    },
    hourlyPrice() {
      return this.requestedServices.hourlyPrice;
    },
    unitsTwo() {
      return this.requestedServices.unitsTwo;
    },
    passedData() {
      return {
        labourQuantity: this.labourQuantity,
        unitsOne: this.unitsOne,
        hourlyPrice: this.hourlyPrice,
        unitsTwo: this.unitsTwo,
        selectedConditions: this.selectedConditions
      };
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "addSelectedCondition",
      "delSelectedCondition",
      "setCurrentSelectedCondition",
      "setLabourQuantity",
      "setUnitsOne",
      "setHourlyPrice",
      "setUnitsTwo"
    ])
  }
};
</script>

<style scoped>
.cm-selection-form__small-select-wrapper {
  display: block;
  width: 100%;
  max-width: 265px;
  margin-top: 60px;
}
</style>
