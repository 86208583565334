<template>
  <div class="cm-general-form">
    <div class="cm-general-form__input-wrapper cm-general-form__input-wrapper--bigger">
      <p class="input-label required">{{ $t("Payment delay") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Payment delay')"
        solo
        :value="paymentDelay"
        @input="setPaymentDelay"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("InvoiceContractModal", ["paymentDelay"])
  },
  methods: {
    ...mapMutations("InvoiceContractModal", ["setPaymentDelay"])
  }
};
</script>

<style scoped>
.cm-general-form {
  display: flex;
  width: 100%;
}
.cm-general-form__input-wrapper {
  display: block;
  width: 300px;
  max-width: calc(50% - 10px);
}
.cm-general-form__input-wrapper--bigger {
  width: 380px;
}
</style>
