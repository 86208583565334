<template>
  <v-col>
    <v-row>
      <v-col>
        <v-carousel :value="activeImg" v-model="activeImg" hide-delimiters v-if="galleryImages">
          <v-container v-for="(item, i) in galleryImages" :key="i">
            <v-carousel-item
              v-if="item.info_type === 'default_doc' && !isFileImage(item.asset_url)"
            >
              <v-sheet color="#424242" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                  <div class="text-h2">
                    File:
                    <a :href="item.asset_url" target="_blank" :title="item.title" download>
                      {{ item.title }} <v-icon large>mdi-download</v-icon>
                    </a>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item :src="item.asset_url" v-else></v-carousel-item>
          </v-container>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <carousel :per-page="5" :mouse-drag="true" :navigationEnabled="true">
          <slide v-for="(item, i) in galleryImages" :key="i">
            <v-card @click="activeImg = i" class="slide-item-gallery">
              <v-img
                v-if="isFileImage(item.asset_url)"
                :src="item.asset_url"
                height="100px"
                width="100px"
              ></v-img>
              <v-img v-else src="@/assets/icons/upload_doc.svg" height="100px" width="85px"></v-img>
            </v-card>
            <p class="mt-1 image-title">{{ item.info_type | startCase }}</p>
          </slide>
        </carousel>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide
  },
  name: "images-gallery",
  props: ["images"],
  data: () => ({
    activeImg: 0
  }),
  computed: {
    galleryImages() {
      const defaultAsset = [
        { asset_url: require(`@/assets/no-image-available-icon.png`), info_type: "None" }
      ];
      const images = this.images && this.images.length ? this.images : defaultAsset;
      return images;
    }
  },
  methods: {
    isFileImage(url) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
      const fileName = url.split("/").pop();
      return imageExtensions.includes(fileName.split(".").pop());
    }
  }
};
</script>

<style>
.slide-item-gallery {
  padding: 5px;
}

.VueCarousel .v-image {
  margin: 0 auto;
}

.VueCarousel .image-title {
  text-align: center;
}

.VueCarousel-navigation .VueCarousel-navigation-button {
  top: 25%;
}
</style>
