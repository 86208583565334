<script>
import { mapState, mapMutations } from "vuex";
import HandlingShipping from "@/components/ContractModals/Components/BaseParts/HandlingShipping";

export default {
  extends: HandlingShipping,
  computed: {
    ...mapState("QuotationContractModal", ["handlingShipping"])
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setHandlingShippingLabourQuantity",
      "setHandlingShippingUnitsOne",
      "setHandlingShippingHourlyPrice",
      "setHandlingShippingUnitsTwo",
      "setHandlingShippingIncoterms"
    ])
  }
};
</script>
