import {
  getOneBid,
  signPurchaseOrder,
  getPurchaseOrderPDF,
  getFinanceAccount,
  declineUnsignedPO,
  getInvoice,
  getInvoicePDF,
  getDownloadRequestPDF
} from "@/api";
import { getUserInfo } from "@/auth/auth";
import { mapState, mapMutations, mapActions } from "vuex";
import ImagesGallery from "@/components/ImagesGallery.vue";
import AdditionalConditions from "@/components/AdditionalConditions.vue";
import RepairTypeIco from "@/components/RepairTypeIco.vue";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";

import TicketChatDialog from "@/components/TicketChat/TicketChatDialog.vue";

import OneItem from "@/components/OrderUsersList/OneUserItem.vue";

// Modal windows
import QuotationModal from "@/components/ContractModals/Modals/QuotationModal.vue";
import InvoiceModal from "@/components/ContractModals/Modals/InvoiceModal.vue";
import ServiceReportModal from "@/components/ContractModals/Modals/ServiceReportModal.vue";
import PDFdocuments from "@/components/PDFdocuments.vue";
import moment from "moment";

export default {
  components: {
    ImagesGallery,
    AdditionalConditions,
    QuotationModal,
    InvoiceModal,
    ErrorsSnackbar,
    RepairTypeIco,
    ServiceReportModal,
    "pdf-documents": PDFdocuments,
    "client-item": OneItem,
    "ticket-chat-dialog": TicketChatDialog
  },
  data: function() {
    return {
      isSignShow: false,
      invoice: null,
      signAgree: false,
      isShowSignError: false,
      isShowDocuments: false,
      bid: {
        id: "id",
        subject_description: {
          subject_info: {
            brand: {},
            manufacturer: {}
          }
        },
        object_address: {},
        subject_attachments: {},
        service_report_doc: {}
      },
      isReportModalOpened: false,
      showModalErrors: false,
      modalErrors: [],
      clientData: {},
      financeAccount: null,
      userInfo: getUserInfo() || {}
    };
  },
  computed: {
    ...mapState("QuotationContractModal", {
      isQuotationModalOpened: (state: any) => state.isModalOpened
    }),
    ...mapState("InvoiceContractModal", {
      quoteId: (state: any) => state.quoteId,
      isInvoiceModalOpened: (state: any) => state.isModalOpened
    }),
    showHideAvailable() {
      const hasQuotDoc = this.bid.quotation_doc;
      const hasPurcDoc = this.bid.purchase_order_doc;
      const hasServDoc = this.bid.service_report_doc;
      return hasQuotDoc || hasPurcDoc || hasServDoc;
    },
    isWarranty() {
      return Boolean(this.bid.warranty_info && this.bid.warranty_info.original_ticket_id);
    },
    isDiagnosticWarrantyTicket() {
      return this.isWarranty && this.bid.ticket_type === "diagnostic";
    },
    isRepairWarrantyTicket() {
      return this.isWarranty && this.bid.ticket_type === "repair";
    },
    isAbortAvailable() {
      const isExpertProfile = this.bid.user_profile_type === "ExpertProfile";
      const isSplitTicket = this.bid.performers_required === "expert_and_technician";
      return (isSplitTicket && isExpertProfile) || !isSplitTicket;
    },
    bidState() {
      return this.bid.state;
    },
    created_date() {
      return moment(this.bid.created_at).format("DD/MM/YYYY HH:mm");
    },
    isDeclined() {
      return this.bidState === "declined";
    },
    validTo() {
      if (!this.ticket.urgency) return;
      return "" + this.ticket.urgency.date_value + " " + this.ticket.urgency.date_item;
    },
    showApplyBtn() {
      return this.bidState && this.bidState !== "applied" && !this.bid.quotation_doc;
    },
    showQuote() {
      return this.bidState != "requested_by_model" && this.bidState != "requested_by_type";
    },
    isServiceReportDoc() {
      return Object.prototype.hasOwnProperty.call(this.bid.service_report_doc, "summary");
    },
    estimatedCost() {
      if (
        this.bid.service_report_doc &&
        this.isServiceReportDoc &&
        this.bid.user_profile_type !== "TechnicianProfile"
      ) {
        return this.bid.service_report_doc.summary.total;
      } else if (this.bid.purchase_order_doc) {
        return this.bid.purchase_order_doc.summary.total;
      } else if (this.bid.quotation_doc) {
        return this.bid.quotation_doc.summary.total;
      } else return "0";
    },
    hasManufacture() {
      return (
        this.hasObjectInInfo("manufacturer") &&
        this.bid.subject_description.subject_info.manufacturer !== null
      );
    },
    hasBrand() {
      return (
        this.hasObjectInInfo("brand") && this.bid.subject_description.subject_info.brand !== null
      );
    }
  },
  mounted() {
    this.getbid();
    this.getAdditionalConditionsList();
    this.getServicesList();
    this.getFinanceAccount();

    getInvoice(this.$route.params["id"])
      .then(r => {
        this.invoice =
          Object.hasOwnProperty.call(r.data, "id") && r.data.id !== null ? r.data : null;
      })
      .catch(e => {
        console.error(e);
        this.invoice = null;
      });
  },
  methods: {
    ...mapActions("QuotationContractModal", ["createNewQuotation"]),
    ...mapActions("InvoiceContractModal", ["sendInvoiceForm"]),
    ...mapMutations({
      // quotation modal
      openQuotationModal: "QuotationContractModal/openModal",
      closeQuotationModal: "QuotationContractModal/closeModal",
      clearQuotationModalData: "QuotationContractModal/clearModalData",
      // invoice modal
      openInvoiceModal: "InvoiceContractModal/openModal",
      closeInvoiceModal: "InvoiceContractModal/closeModal",
      clearInvoiceModalData: "InvoiceContractModal/clearModalData",
      setTicketType: "InvoiceContractModal/setTicketType"
    }),

    goToOriginal() {
      document.location.pathname = `/bid/${this.bid.warranty_info.original_quote_request_id}`;
    },

    // go to customer profile page
    goToPerformerProfile(q) {
      this.$router.push({
        name: "ShowUserProfile",
        params: { id: q.owner_id },
        query: { profile_view: "customer-profile" }
      });
    },

    getFinanceAccount() {
      getFinanceAccount(this.userInfo.id).then(r => {
        if (r.data !== null && r.data.id) {
          this.financeAccount = r.data;
        } else {
          this.financeAccount = null;
        }
      });
    },

    viewInvoice(qid) {
      getInvoicePDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },

    downloadRequest(qid) {
      getDownloadRequestPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },

    withdrawPO(qid) {
      declineUnsignedPO(qid)
        .then(() => {
          document.location.reload();
        })
        .catch(e => {
          console.error(e);
          this.errorDialog = true;
          this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
        });
    },

    catchApply() {
      this.showModalErrors = false;
      this.createNewQuotation()
        .then(() => {
          this.getbid();
          this.closeQuotationModal();
          this.clearQuotationModalData();
        })
        .catch(e => {
          if (
            Object.hasOwnProperty.call(e, "response") &&
            Object.hasOwnProperty.call(e.response, "data") &&
            Object.hasOwnProperty.call(e.response.data, "errors")
          ) {
            this.showModalErrors = true;
            this.modalErrors = e.response.data.errors[0];
          } else {
            document.location.reload();
          }
        });
    },

    catchInvoiceSend() {
      this.showModalErrors = false;
      this.sendInvoiceForm(this.quoteId)
        .then(() => {
          document.location.reload();
          // this.getbid();
          // this.closeInvoiceModal();
          // this.clearInvoiceModalData();
        })
        .catch(e => {
          this.showModalErrors = true;
          this.modalErrors = e.response.data.errors;
        });
    },

    getbid() {
      getOneBid(this.$route.params["id"])
        .then(r => {
          this.bid = r.data;
          this.setTicketType(this.bid.ticket_type);
          const hasQuotation =
            Object.hasOwnProperty.call(this.bid, "quotation_attributes") &&
            this.bid.quotation_attributes !== null;
          this.clientData = {
            performer_info: {
              avatar_url: this.bid.client_avatar,
              name: this.bid.client_name
            },
            estimated_cost: hasQuotation ? this.bid.quotation_attributes.estimated_cost : 0,
            estimated_service_time: hasQuotation ? this.bid.quotation_attributes.estimated_time : 0,
            conversation_id: hasQuotation ? this.bid.quotation_attributes.conversation_id : 0,
            purchase_order_doc: this.bid.purchase_order_doc
          };
        })
        .catch(() => {
          this.$router.push({ name: "SearchExpert" });
        });
    },

    getAdditionalConditionsList() {
      if (!this.$store.state.additionalConditions.length) {
        this.$store.dispatch("getAdditionalConditions");
      }
    },

    getServicesList() {
      if (!this.$store.state.servicesList.length) {
        this.$store.dispatch("getServicesList");
      }
    },

    viewPurchaseOrder(qid) {
      getPurchaseOrderPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },

    signPO() {
      if (this.signAgree) {
        this.isShowSignError = false;
        signPurchaseOrder(this.bid.id)
          .then(() => {
            this.isSignShow = false;
            this.getbid();
          })
          .catch(e => {
            this.showModalErrors = true;
            this.modalErrors = [e.response.data.error];
          });
      } else {
        this.isShowSignError = true;
      }
    },
    hasObjectInTicket(key) {
      return Object.hasOwnProperty.call(this.bid, key);
    },
    hasObjectInDescription(key) {
      return Object.hasOwnProperty.call(this.bid.subject_description, key);
    },
    hasObjectInInfo(key) {
      return (
        this.hasObjectInDescription("subject_info") &&
        Object.hasOwnProperty.call(this.bid.subject_description.subject_info, key)
      );
    }
  },
  beforeDestroy() {
    this.clearQuotationModalData();
    this.clearInvoiceModalData();
  }
};
