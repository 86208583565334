<script>
import { mapState, mapMutations } from "vuex";
import Summary from "@/components/ContractModals/Components/BaseParts/Summary";

export default {
  extends: Summary,
  computed: {
    ...mapState("InvoiceContractModal", ["prices"])
  },
  methods: {
    ...mapMutations("InvoiceContractModal", ["setSubtotal", "setVat", "setTotal"])
  }
};
</script>
