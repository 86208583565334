<script>
import { mapState, mapMutations } from "vuex";
import LimitedWarranty from "@/components/ContractModals/Components/BaseParts/LimitedWarranty";

export default {
  extends: LimitedWarranty,
  computed: {
    ...mapState("InvoiceContractModal", ["limitedWarranty"])
  },
  watch: {
    labourQuantity() {
      this.setLimitedWarrantyPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    },
    hourlyPrice() {
      this.setLimitedWarrantyPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    }
  },
  methods: {
    ...mapMutations("InvoiceContractModal", [
      "setLimitedWarrantyLabourQuantity",
      "setLimitedWarrantyUnitsOne",
      "setLimitedWarrantyHourlyPrice",
      "setLimitedWarrantyUnitsTwo",
      "setLimitedWarrantyTerm",
      "setLimitedWarrantyPrice"
    ])
  }
};
</script>
