<script>
import { mapState, mapMutations } from "vuex";
import HandlingShipping from "@/components/ContractModals/Components/BaseParts/HandlingShipping";

export default {
  extends: HandlingShipping,
  computed: {
    ...mapState("InvoiceContractModal", ["handlingShipping"])
  },
  watch: {
    labourQuantity() {
      this.setHandlingShippingPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    },
    hourlyPrice() {
      this.setHandlingShippingPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    }
  },
  methods: {
    ...mapMutations("InvoiceContractModal", [
      "setHandlingShippingLabourQuantity",
      "setHandlingShippingUnitsOne",
      "setHandlingShippingHourlyPrice",
      "setHandlingShippingUnitsTwo",
      "setHandlingShippingIncoterms",
      "setHandlingShippingPrice"
    ])
  }
};
</script>
