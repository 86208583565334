<template>
  <contract-modal
    :show-modal="showModal"
    :btn-text="btnText"
    @closemodal="closeModal"
    @formsubmitted="$emit('formsubmitted')"
    v-bind:disableNext.sync="nextButtonDisabled"
  >
    <template v-slot:header>
      <date-header
        title="Fill up and send your Invoice"
        :date="date"
        :number="headerNbr"
        @closemodal="closeModal"
      />
    </template>
    <p class="cm-modal__info-text">{{ manufacturerInfo }}</p>
    <cm-section
      v-for="(section, index) in sections"
      :title="section.title"
      :disabled="skipBlock(section.component)"
      :key="index"
    >
      <section v-if="section.syncRequiredFilled">
        <component
          v-if="!skipBlock(section.component)"
          v-bind:required-fields-filled.sync="allFieldFilled[section.component]"
          v-on:update:required-fields-filled="nextButtonDisabledCheck()"
          :is="section.component"
        ></component>
      </section>
      <section v-else>
        <component v-if="!skipBlock(section.component)" :is="section.component"></component>
      </section>
    </cm-section>
  </contract-modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ContractModal from "@/components/ContractModals/ContractModalSkeleton";
import DateHeader from "@/components/ContractModals/Components/DateHeader";
import ModalSection from "@/components/ContractModals/Components/ModalSection";
// PARTS
import GeneralForm from "@/components/ContractModals/Components/InvoiceParts/GeneralForm";
import SelectionForm from "@/components/ContractModals/Components/InvoiceParts/SelectionForm";
import PartsMaterials from "@/components/ContractModals/Components/InvoiceParts/PartsMaterials";
import LimitedWarranty from "@/components/ContractModals/Components/InvoiceParts/LimitedWarranty";
import HandlingShipping from "@/components/ContractModals/Components/InvoiceParts/HandlingShipping";
import Summary from "@/components/ContractModals/Components/InvoiceParts/Summary";

export default {
  components: {
    "contract-modal": ContractModal,
    "date-header": DateHeader,
    "cm-section": ModalSection,
    "general-form": GeneralForm,
    "selection-form": SelectionForm,
    "parts-materials-form": PartsMaterials,
    "limited-warranty-form": LimitedWarranty,
    "handling-shipping-form": HandlingShipping,
    "cm-summary": Summary
  },
  props: {
    data: {
      type: Object
    },
    showModal: {
      type: Boolean
    },
    btnText: {
      type: String
    }
  },
  data() {
    return {
      nextButtonDisabled: false,
      allFieldFilled: {
        "general-form": false,
        "selection-form": true,
        "parts-materials-form": true,
        "limited-warranty-form": true,
        "hadnling-shipping-form": true
      },
      sections: [
        {
          title: this.$t("General"),
          component: "general-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Requested service"),
          component: "selection-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Parts and materials"),
          component: "parts-materials-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Limited warranty"),
          component: "limited-warranty-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Handling and shipping"),
          component: "handling-shipping-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        { title: this.$t("Summary"), component: "cm-summary" }
      ]
    };
  },
  computed: {
    ...mapState("InvoiceContractModal", ["ticketType"]),
    isDiagnostic() {
      const ticketType = this.ticketType ? this.ticketType : this.data.ticket_type;
      return ticketType === "diagnostic";
    },
    quotationData() {
      return this.data || {};
    },
    quotationDoc() {
      return this.quotationData.quotation_doc || {};
    },
    headerNbr() {
      return this.quotationDoc.number;
    },
    manufacturerInfo() {
      return this.quotationData.title;
    },
    date() {
      return this.quotationDoc.date;
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.setServiceCurrency(this.quotationData.purchase_order_doc.purchase_order_currency);
        this.setInvoiceData(this.quotationDoc);
      } else {
        this.clearModalData();
      }
    }
  },
  methods: {
    ...mapMutations("InvoiceContractModal", [
      "setInvoiceData",
      "closeModal",
      "clearModalData",
      "setServiceCurrency"
    ]),

    nextButtonDisabledCheck() {
      let enable = true;
      const keys = Object.keys(this.allFieldFilled);
      for (const name in keys) {
        const key = keys[name];
        enable = this.allFieldFilled[key];

        if (!enable) {
          break;
        }
      }
      this.nextButtonDisabled = !enable;
    },
    skipBlock(component) {
      return this.isDiagnostic && component === "limited-warranty-form";
    }
  }
};
</script>

<style scoped>
.cm-modal__info-text {
  display: block;
  width: 100%;
  margin-bottom: 45px;
  font-size: 24px;
  font-weight: bold;
}
</style>
