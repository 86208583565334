<script>
import { mapState, mapMutations } from "vuex";
import GeneralForm from "@/components/ContractModals/Components/BaseParts/GeneralForm";

export default {
  extends: GeneralForm,
  computed: {
    ...mapState("QuotationContractModal", [
      "paymentDelay",
      "minimumServiceAmount",
      "estimatedServiceTime",
      "workScheduleFrom",
      "workScheduleTo"
    ])
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setPaymentDelay",
      "setMinimumServiceAmount",
      "setEstimatedServiceTime",
      "setWorkScheduleFrom",
      "setWorkScheduleTo"
    ])
  }
};
</script>
