<template>
  <div class="user-item">
    <!-- First layer-->
    <div class="user-item__general-info">
      <router-link
        :to="{ name: 'ShowUserProfile', props: { id: userData.id } }"
        class="user-item__name-avatar-wrapper"
      >
        <div class="user-item__avatar-wrapper">
          <v-avatar color="#C4C4C4" :size="90" rounded>
            <v-img
              v-if="userData.performer_info.avatar_url"
              :alt="userData.name"
              :src="userData.performer_info.avatar_url"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else>mdi-account</v-icon>
          </v-avatar>
        </div>
        <div class="user-item__name-cont">
          <p class="user-item__name">{{ userData.performer_info.name }}</p>
          <v-rating
            :background-color="'#C4C4C4'"
            :color="'#FFA800'"
            :readonly="true"
            :value="userData.rating"
            dense
          />
          <p class="user-item__licenses">
            <span>{{ $t("Licenses") }}</span> <b>{{ userData.licenses }}</b>
          </p>
        </div>
      </router-link>
      <div class="user-item__table-wrapper">
        <table class="user-item__table">
          <tr>
            <td class="user-item__table-item">{{ $t("Estimated cost, $") }}:</td>
            <td class="user-item__table-item">${{ userData.estimated_cost | currency }}</td>
          </tr>
          <tr>
            <td class="user-item__table-item">{{ $t("Estimated service time") }}:</td>
            <td class="user-item__table-item">{{ compDuration }}</td>
          </tr>
        </table>
      </div>
      <div class="user-item__btns-cont">
        <slot name="quote-actions"></slot>
      </div>
    </div>

    <!-- Second layer-->
    <div
      v-if="showSecondLayer"
      class="user-item__additinal-info user-item__additinal-info--user-data"
    >
      <ticket-chat-dialog :contractor="userData" :is_bid="false" />
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="userData.quotation_doc"
        @click="viewQuotation(userData.quotation_doc.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Quotation") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="userData.purchase_order_doc"
        @click="viewPurchaseOrder(userData.quote_request_id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Purchase Order") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="userData.service_report_doc"
        @click="viewServiceReport(userData.quote_request_id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Service report") }}</span>
      </v-btn>
    </div>

    <!-- Third layer-->
    <div
      v-if="showThirdLayer"
      class="user-item__additinal-info user-item__additinal-info--invoice-data"
    >
      <p class="user-item__total">
        <span class="user-item__total-title">{{ $t("Total cost") }}:</span>
        <span class="user-item__total-price">{{ totalPriceText }}</span>
      </p>
      <div class="user-item__btns-cont">
        <slot name="invoice-actions"></slot>
      </div>
    </div>
    <slot name="quote-modals"></slot>
  </div>
</template>

<script>
import TicketChatDialog from "@/components/TicketChat/TicketChatDialog";
import { getQuotationPDF, getPurchaseOrderPDF, getServiceReportPDF } from "@/api";

export default {
  props: {
    userData: Object,
    ticketData: Object,
    showSecondLayer: Boolean,
    showThirdLayer: Boolean
  },
  components: {
    "ticket-chat-dialog": TicketChatDialog
  },
  computed: {
    totalPriceText() {
      return `$${this.ticketData.budget}`;
    },
    compDuration() {
      const days = parseInt(this.userData.estimated_service_time / 24);
      const hours = this.userData.estimated_service_time - days * 24;
      return `${days ? days + this.$t("days") : ""} ${
        hours ? hours + this.$t("hours") : ""
      }`.trim();
    }
  },
  methods: {
    viewQuotation(qid) {
      getQuotationPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewPurchaseOrder(qid) {
      getPurchaseOrderPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewServiceReport(qid) {
      getServiceReportPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }
};
</script>

<style scoped>
.user-item:not(:last-of-type) {
  margin-bottom: 40px;
}
.user-item__general-info {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.user-item__name-avatar-wrapper {
  display: flex;
  align-items: center;
  width: 290px;
  text-decoration: none;
  color: #000000;
  border-right: 1px solid #aeaeae;
}
.user-item__avatar-wrapper {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 90px;
  height: 90px;
  margin-right: 20px;
}
.user-item__name-cont {
  display: block;
  flex-grow: 1;
}
.user-item__name,
.user-item__licenses {
  display: block;
  width: 100%;
  margin-bottom: 0;
  font-size: 18px;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user-item__licenses {
  color: #aeaeae;
}
.user-item__licenses b {
  display: inline-block;
  margin-left: 5px;
  color: #000000;
}
.user-item__table-wrapper {
  display: inline-block;
  width: auto;
  padding-left: 30px;
}
.user-item__table {
  table-layout: fixed;
  font-size: 20px;
  color: #aeaeae;
}
.user-item__table-item {
  padding: 10px 0;
}
.user-item__table-item:last-of-type {
  padding-left: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}
.user-item__btns-cont {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}
.user-item__additinal-info {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: -15px;
  padding: 35px 30px 15px;
  background: #ffffff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 12px 12px;
}
.user-item__additinal-info--invoice-data {
  z-index: 8;
  padding: 55px 30px 40px;
}
.user-item__total {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  color: #b3b3b3;
}
.user-item__total-title {
  margin-right: 15px;
}
.user-item__total-price {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}
</style>
