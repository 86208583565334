<script>
import { mapState, mapMutations } from "vuex";
import SelectionForm from "@/components/ContractModals/Components/BaseParts/SelectionForm";

export default {
  extends: SelectionForm,
  computed: {
    ...mapState("InvoiceContractModal", ["requestedServices"]),
    passedData() {
      return {
        labourQuantity: this.labourQuantity,
        unitsOne: this.unitsOne,
        hourlyPrice: this.hourlyPrice,
        unitsTwo: this.unitsTwo,
        selectedConditions: this.selectedConditions,
        price: this.servicePrice
      };
    }
  },
  watch: {
    labourQuantity() {
      this.setServicesPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    },
    hourlyPrice() {
      this.setServicesPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    }
  },
  methods: {
    ...mapMutations("InvoiceContractModal", [
      "addSelectedCondition",
      "delSelectedCondition",
      "setCurrentSelectedCondition",
      "setLabourQuantity",
      "setUnitsOne",
      "setHourlyPrice",
      "setUnitsTwo",
      "setServicesPrice"
    ])
  }
};
</script>
