<template>
  <div class="cm-general-form">
    <div class="cm-general-form__input-wrapper">
      <v-checkbox
        :value="true"
        v-model="is_warranty"
        @change="updateWarranty"
        :label="$t('It is warranty case')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      is_warranty: false
    };
  },
  computed: {
    ...mapState("ServiceReportContractModal", ["warranty_case"])
  },
  methods: {
    ...mapMutations("ServiceReportContractModal", ["setIsWarrantyCase"]),
    updateWarranty() {
      this.setIsWarrantyCase(Boolean(this.is_warranty));
    }
  },
  mounted() {
    this.setIsWarrantyCase(Boolean(this.is_warranty));
  }
};
</script>

<style scoped>
.cm-general-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.cm-general-form__input-wrapper {
  display: block;
  width: calc(50% - 10px);
}
.cm-general-form__input-wrapper--smaller,
.cm-general-form__select-wrapper {
  width: calc(33.3333333333% - 10px);
}
.cm-general-form__select-wrapper--with-margin {
  margin-top: 32px;
}
</style>
