<template>
  <contract-modal
    :show-modal="showModal"
    :btn-text="btnText"
    @closemodal="closeModal"
    @formsubmitted="$emit('formsubmitted')"
    v-bind:disableNext.sync="nextButtonDisabled"
  >
    <template v-slot:header>
      <date-header :title="modalTitle" :date="date" :number="headerNbr" @closemodal="closeModal" />
    </template>
    <cm-section
      v-for="(section, index) in activeSectionComponents()"
      :title="section.title"
      :key="index"
      :disabled="skipBlock(section.component)"
      :class="section.class"
    >
      <section v-if="section.syncRequiredFilled">
        <component
          :is="section.component"
          v-if="!skipBlock(section.component)"
          v-bind:required-fields-filled.sync="allFieldFilled[section.component]"
          v-on:update:required-fields-filled="nextButtonDisabledCheck()"
        ></component>
      </section>
      <section v-else>
        <component v-if="!skipBlock(section.component)" :is="section.component"></component>
      </section>
    </cm-section>
  </contract-modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import ContractModal from "@/components/ContractModals/ContractModalSkeleton";
import DateHeader from "@/components/ContractModals/Components/DateHeader";
import ModalSection from "@/components/ContractModals/Components/ModalSection";
// PARTS
import GeneralForm from "@/components/ContractModals/Components/QuotationParts/GeneralForm";
import UrgencyStartForm from "@/components/ContractModals/Components/QuotationParts/UrgencyStartForm";
import SelectionForm from "@/components/ContractModals/Components/QuotationParts/SelectionForm";
import PartsMaterials from "@/components/ContractModals/Components/QuotationParts/PartsMaterials";
import LimitedWarranty from "@/components/ContractModals/Components/QuotationParts/LimitedWarranty";
import HandlingShipping from "@/components/ContractModals/Components/QuotationParts/HandlingShipping";
import Summary from "@/components/ContractModals/Components/QuotationParts/Summary";
import SummaryWarrantyRepair from "@/components/ContractModals/Components/QuotationParts/SummaryWarrantyRepair";

export default {
  components: {
    "contract-modal": ContractModal,
    "date-header": DateHeader,
    "cm-section": ModalSection,
    "general-form": GeneralForm,
    "urgency-start-form": UrgencyStartForm,
    "selection-form": SelectionForm,
    "parts-materials-form": PartsMaterials,
    "limited-warranty-form": LimitedWarranty,
    "hadnling-shipping-form": HandlingShipping,
    "cm-summary": Summary,
    "cm-summary-warranty-repair": SummaryWarrantyRepair
  },
  props: {
    data: {
      type: Object
    },
    showModal: {
      type: Boolean
    },
    btnText: {
      type: String
    }
  },
  data() {
    return {
      nextButtonDisabled: true,
      allFieldFilled: {
        "general-form": false,
        "urgency-start-form": false,
        "selection-form": true,
        "parts-materials-form": false,
        "limited-warranty-form": true,
        "hadnling-shipping-form": false
      },
      sections: [
        {
          title: this.$t("General"),
          component: "general-form",
          class: "cm-modal__general-section",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Urgency-Start within"),
          component: "urgency-start-form",
          class: "cm-modal__urgency-section",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Requested service"),
          component: "selection-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Parts and materials"),
          component: "parts-materials-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Limited warranty"),
          component: "limited-warranty-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Handling and shipping"),
          component: "hadnling-shipping-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        { title: this.$t("Summary"), component: "cm-summary" }
      ]
    };
  },
  computed: {
    ...mapState("InvoiceContractModal", ["ticketType"]),
    modalTitle() {
      let title = this.$t("Fill up and send your Quotation");
      if (this.isWarrantyRepair) {
        title = this.$t("Warranty Repairment Process");
      }
      return title;
    },
    isDiagnostic() {
      return this.ticketType === "diagnostic";
    },
    isRepair() {
      return !this.isDiagnostic;
    },
    isWarranty() {
      return Boolean(
        this.quotationData.warranty_info && this.quotationData.warranty_info.original_ticket_id
      );
    },
    isWarrantyRepair() {
      return this.isWarranty && this.isRepair;
    },
    isWarrantyDiagnostic() {
      return this.isWarranty && this.isDiagnostic;
    },
    quotationData() {
      return this.data || {};
    },
    headerNbr() {
      return this.quotationData.number;
    },
    manufacturerInfo() {
      return this.quotationData.title;
    },
    date() {
      return moment().format("YYYY-MM-DD");
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.setServiceCurrency(this.quotationData.currency);
        this.setQuoteId(this.$route.params.id);
      } else {
        this.clearModalData();
      }
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setTicketType",
      "setQuoteId",
      "closeModal",
      "clearModalData",
      "setServiceCurrency"
    ]),
    activeSectionComponents() {
      const componentsData = this.sections;
      return componentsData;
    },
    nextButtonDisabledCheck() {
      let enable = true;
      const keys = Object.keys(this.allFieldFilled);
      for (const name in keys) {
        const key = keys[name];
        enable = this.allFieldFilled[key];

        if (!enable) {
          break;
        }
      }

      this.nextButtonDisabled = !enable;
    },
    skipBlock(component) {
      const warrantyComponent = component === "limited-warranty-form";
      return (
        (this.isDiagnostic && warrantyComponent) || (this.isWarrantyDiagnostic && warrantyComponent)
      );
    }
  }
};
</script>

<style scoped>
.cm-modal__info-text {
  display: block;
  width: 100%;
  margin-bottom: 45px;
  font-size: 24px;
  font-weight: bold;
}
.cm-modal__half-width-section {
  width: calc(50% - 20px);
}
.cm-modal__general-section {
  width: calc(55% - 20px);
}
.cm-modal__urgency-section {
  width: calc(45% - 20px);
}
</style>
