<script>
import { mapState, mapMutations } from "vuex";
import PartsMaterials from "@/components/ContractModals/Components/BaseParts/PartsMaterials";

export default {
  extends: PartsMaterials,
  computed: {
    ...mapState("InvoiceContractModal", ["partsMaterials"])
  },
  watch: {
    labourQuantity() {
      this.setPartsMaterialsPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    },
    hourlyPrice() {
      this.setPartsMaterialsPrice({ labour: this.labourQuantity, price: this.hourlyPrice });
    }
  },
  methods: {
    ...mapMutations("InvoiceContractModal", [
      "setPartsMaterialsLabourQuantity",
      "setPartsMaterialsUnitsOne",
      "setPartsMaterialsHourlyPrice",
      "setPartsMaterialsUnitsTwo",
      "setPartsMaterialsPrice"
    ])
  }
};
</script>
