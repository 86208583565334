<template>
  <v-dialog v-model="isModalOpened" scrollable max-width="1245px">
    <template v-slot:activator="{ on }" v-if="isAttachReportAvailable && !isEditReport">
      <v-btn v-on="on" color="primary" @click="isModalOpened = true">
        {{ getButtonText }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="contract-modal__title">
        <usual-header
          :hideSteps="isAssemblyRepair"
          :title="modalTitle"
          :step.sync="step"
          :number="modalNumber"
        />
      </v-card-title>

      <v-card-text>
        <form class="contract-modal__content mt-3" @submit.stop.prevent="$emit('formsubmitted')">
          <v-window v-model="step" class="pa-4">
            <v-window-item :value="1" v-if="!isAssemblyRepair">
              <div class="row">
                <v-col md="6">
                  <label class="input-lable">Main report info</label>
                  <v-textarea height="300px" solo v-model="form.service_report.info"> </v-textarea>
                </v-col>
                <v-col md="6" v-if="this.data.ticket_type === 'diagnostic'">
                  <label class="input-label">
                    {{ $t(`Recommended speciality`) }}
                  </label>
                  <v-select
                    id="language_ids"
                    :items="specItems"
                    v-model="newSpecialities"
                    :label="$t(`Recommended speciality`)"
                    solo
                    return-object
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        fab
                        depressed
                        color="primary"
                        :disabled="!newSpecialities"
                        @click="addNewSpec(newSpecialities)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </v-select>
                  <p
                    :title="spec.value"
                    solo
                    v-for="spec in selectedSpecialities"
                    :key="spec.value"
                  >
                    <v-chip close block color="transparent" @click:close="removeSpec(spec)">
                      {{ spec.text }}
                    </v-chip>
                  </p>
                </v-col>
                <v-col md="6" v-if="data.ticket_type === 'repair'">
                  <label class="input-label">
                    {{ $t(`Approve correct diagnostic reports`) }}
                  </label>
                  <v-divider class="mb-4"></v-divider>
                  <div
                    class="report-item row"
                    v-for="(report, index) in form.service_report.diagnostic_reports"
                    :key="report.id"
                  >
                    <v-col md="10" class="report-item__checkbox">
                      <v-checkbox
                        v-model="form.service_report.diagnostic_reports[index]['approved']"
                        :value="!report.approved"
                        :label="report.title"
                        :disabled="report.isDisabled"
                        @change="checkDiagnosticReports"
                      />
                    </v-col>
                    <v-col md="2" class="text-center align-center">
                      <a class="report-item__show" @click="viewServiceReport(report.id)">
                        Show
                      </a>
                    </v-col>
                  </div>
                </v-col>
              </div>
              <v-row class="d-flex justify-space-between">
                <v-btn
                  class="contract-modal__btn "
                  color="primary"
                  outlined
                  @click="isModalOpened = false"
                >
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                  class="contract-modal__btn"
                  :disabled="!isServiceReportsChecked"
                  color="primary"
                  @click="step++"
                >
                  {{ $t("Next") }}
                </v-btn>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <cm-section
                v-for="(section, index) in sections"
                :disabled="skipBlock(section.component)"
                :title="section.title"
                :key="index"
              >
                <component
                  v-bind="{ isShowRate: false, unitsList: unitList }"
                  :is="section.component"
                ></component>
              </cm-section>
              <div class="contract-modal__btn-wrapper">
                <slot name="terms"></slot>
                <v-row class="d-flex justify-space-between">
                  <v-btn
                    v-if="isAssemblyRepair"
                    class="contract-modal__btn "
                    color="primary"
                    outlined
                    @click="isModalOpened = false"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    v-if="!isAssemblyRepair"
                    class="contract-modal__btn"
                    outlined
                    color="primary"
                    @click="step--"
                  >
                    {{ $t("Back") }}
                  </v-btn>
                  <v-btn
                    class="contract-modal__btn float-right"
                    color="primary"
                    @click="catchReportSend"
                  >
                    {{ btnText }}
                  </v-btn>
                </v-row>
              </div>
            </v-window-item>
          </v-window>
        </form>
      </v-card-text>
    </v-card>
    <errors-snackbar :show.sync="showModalErrors" :errors="modalErrors"> </errors-snackbar>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { createServiceReport, getDiagnosticReportPDF, serviceReportsList } from "@/api";
import ContractModal from "@/components/ContractModals/ContractModalSkeleton";
import UsualHeader from "@/components/ContractModals/Components/UsualHeader";
import ModalSection from "@/components/ContractModals/Components/ModalSection";
// PARTS
// import GeneralForm from "@/components/ContractModals/Components/ServiceReportParts/GeneralForm";
import SelectionForm from "@/components/ContractModals/Components/ServiceReportParts/SelectionForm";
import WarrantyForm from "@/components/ContractModals/Components/ServiceReportParts/WarrantyForm";
// import Summary from "@/components/ContractModals/Components/ServiceReportParts/Summary";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import { getUserInfo } from "@/auth/auth";

import { map, without } from "lodash";

export default {
  name: "service-report-modal",
  components: {
    "contract-modal": ContractModal,
    "usual-header": UsualHeader,
    "cm-section": ModalSection,
    // "general-form": GeneralForm,
    "selection-form": SelectionForm,
    "warranty-form": WarrantyForm,
    // "cm-summary": Summary
    ErrorsSnackbar
  },
  props: {
    isEditReport: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    showModal: {
      type: Boolean,
      default: true
    },
    btnText: {
      type: String
    }
  },
  mounted() {
    this.setServiceCurrency(this.data.currency);
    // const fixedFee = 9.0;
    // this.setSubtotal(this.data.quotation_doc.summary.subtotal);
    // this.setVat(this.data.quotation_doc.summary.vat);
    // this.setFixedFee(fixedFee);
    // this.setTotal(this.data.quotation_doc.summary.total);
    // machine_repair
    this.form.service_report.diagnostic_reports = [];

    serviceReportsList(this.data.id)
      .then(res => {
        const userInfo = getUserInfo();
        for (const item of res.data) {
          let isApproved = true;
          if (this.isEditReport) {
            const activeReportsList = this.data.service_report_doc.diagnostic_tickets;
            for (const ticket in activeReportsList) {
              isApproved = activeReportsList[ticket]["id"] === item.id;
              if (isApproved) {
                break;
              }
            }
          }

          this.form.service_report.diagnostic_reports.push({
            id: item.id,
            title: item.title,
            approved: isApproved,
            isDisabled: userInfo.id === item.user_id
          });
        }
      })
      .catch(e => {
        console.log(e);
      });

    if (this.isEditReport) {
      this.form.service_report.specialities = this.data.service_report_doc.specialities;
      this.selectedSpecialities = this.specItems.filter(
        value => value.value === this.data.service_report_doc.specialities[0]
      );
      this.requestedServices.selectedConditions = this.data.service_report_doc.services;
      this.requestedServices.labourQuantity = this.data.service_report_doc.service_count;
      this.requestedServices.unitsOne = this.data.service_report_doc.service_unit_type;
      this.form.service_report.summary = this.data.service_report_doc.summary;
      this.form.service_report.service_currency = this.serviceCurrency;
      this.form.service_report.info = this.data.service_report_doc.info;
    } else {
      this.requestedServices.selectedConditions = this.data.quotation_doc.requested_service.services;
      this.requestedServices.labourQuantity = this.data.quotation_doc.requested_service.service_count;
      this.requestedServices.unitsOne = this.data.quotation_doc.requested_service.service_unit_type;
      this.form.service_report.summary = {
        vat: this.data.quotation_doc.summary.vat,
        total: this.data.quotation_doc.summary.total,
        subtotal: this.data.quotation_doc.summary.subtotal
      };
    }
  },
  methods: {
    skipBlock(name) {
      return name === "warranty-form" && !this.showWarrantySelection;
    },
    checkDiagnosticReports() {
      const reports = this.form.service_report.diagnostic_reports;
      let isChecked = false;
      for (const report in reports) {
        if (reports[report].approved) {
          isChecked = true;
        }
      }
      this.isServiceReportsChecked = isChecked;
    },
    catchReportSend() {
      this.showModalErrors = false;
      this.form.service_report.title = this.data.quotation_doc.number;
      this.form.service_report.service_count = this.requestedServices.labourQuantity;
      this.form.service_report.service_unit_type = this.requestedServices.unitsOne;
      this.form.service_report.service_currency = this.serviceCurrency;
      this.form.service_report.warranty_case = this.warranty_case;
      this.form.service_report.services = map(this.requestedServices.selectedConditions, r => {
        return r["id"];
      });

      createServiceReport(this.$route.params["id"], this.form)
        .then(() => {
          document.location.reload();
          // this.getbid();
          // this.isModalOpened = false;
          // this.clearReportModalData();
        })
        .catch(e => {
          this.showModalErrors = true;
          this.modalErrors = e.response.data.error || e.response.data.errors;
        });
    },
    addNewSpec(spec) {
      this.form.service_report.specialities = [spec.value];
      this.selectedSpecialities = [spec];
    },
    removeSpec(spec) {
      this.form.service_report.specialities = without(this.form.service_report.specialities, spec);
      this.selectedSpecialities = [];
    },
    viewServiceReport(id) {
      getDiagnosticReportPDF(this.data.id, id)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    ...mapMutations("PurchaseOrderSt2ContractModal", [
      "setTerms",
      "closeModal",
      "setPurchaseOrderData",
      "clearModalData"
    ]),
    ...mapMutations("QuotationContractModal", [
      "setQuoteId",
      "setVat",
      "setSubtotal",
      "setFixedFee",
      "setPlatformFee",
      "setTotal",
      "setServiceCurrency"
    ])
  },
  computed: {
    ...mapState("ServiceReportContractModal", ["warranty_case"]),
    isDiagnostic() {
      return this.data.ticket_type === "diagnostic";
    },
    isWarrantyRequest() {
      return Boolean(this.data.warranty_info && this.data.warranty_info.original_ticket_id);
    },
    showWarrantySelection() {
      return Boolean(this.isDiagnostic && this.isWarrantyRequest);
    },
    getButtonText() {
      if (this.isAssemblyRepair) {
        return this.isEditReport ? this.$t("Edit Packing slip") : this.$t("Packing slip");
      }
      return this.isEditReport ? this.$t("Edit report") : this.$t("Attach report");
    },
    isAttachReportAvailable() {
      const isExpertProfile = this.data.user_profile_type === "ExpertProfile";
      const isSplitTicket = this.data.performers_required === "expert_and_technician";
      return (isSplitTicket && isExpertProfile) || !isSplitTicket;
    },
    isAssemblyRepair() {
      return this.data.subject_description.repair_type === "assembly_repair";
    },
    unitList() {
      return !this.isAssemblyRepair ? ["hours"] : ["pcs"];
    },
    modalTitle() {
      if (this.isAssemblyRepair) {
        return this.$t("Packing slip");
      }
      return this.step === 1 ? this.$t("Technical report") : this.$t("Service report");
    },
    modalNumber() {
      const titleNumber = this.data.number;
      if (this.isAssemblyRepair) {
        return titleNumber.replace("Rq", "Pc");
      }
      return this.step === 1 ? titleNumber.replace("Rq", "Tr") : titleNumber.replace("Rq", "Sr");
    },
    ...mapState("ServiceReportContractModal", ["paymentDelay", "serviceTime"]),
    ...mapState("QuotationContractModal", ["requestedServices", "serviceCurrency"])
  },
  data() {
    return {
      isServiceReportsChecked: true,
      specItems: [
        { value: "mechanic", text: this.$t("mechanic") },
        { value: "electrician", text: this.$t("electrician") },
        { value: "machine_control", text: this.$t("machine_control") },
        { value: "electronic", text: this.$t("electronic") },
        { value: "programmer", text: this.$t("programmer") },
        { value: "electromechanic", text: this.$t("electromechanic") }
      ],
      newSpecialities: "",
      selectedSpecialities: [],
      // TODO: WTF?
      form: {
        service_report: {
          info: "Text",
          services: ["1", "2"],
          specialities: [],
          service_count: 100,
          service_unit_type: "hours",
          service_rate: 20,
          service_currency: this.serviceCurrency,
          incoterms: "EXW",
          title: "1Sr-20-03-Apr-081426121",
          report_type: "Service report",
          payment_delay_in_days: 30,
          estimated_service_time: 1212,
          warranty_case: false,
          summary: {
            vat: 130,
            total: 1130,
            subtotal: 1000
          },
          diagnostic_reports: []
          // diagnostic_tickets: []
        }
      },
      step: 1,
      isModalOpened: false,
      showModalErrors: false,
      modalErrors: [],
      sections: [
        // { title: this.$t("General"), component: "general-form" },
        { title: this.$t("Requested service"), component: "selection-form" },
        {
          title: this.$t("Is that warranty case?"),
          component: "warranty-form"
        }
        // { title: this.$t("Summary"), component: "cm-summary" }
      ]
      // MOCK!
    };
  }
};
</script>
<style>
.report-item__checkbox .v-input__slot {
  margin-bottom: 0;
}

.report-item__checkbox .v-messages {
  display: none;
}

.report-item__checkbox .v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}
</style>
